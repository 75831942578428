$(function(){
    $('a[href^="#"]').click(function(){
        let speed = 500;
        let href= $(this).attr("href");
        let target = $(href === "#" || href === "" ? 'html' : href);
        let position = target.offset().top;
        $("html, body").animate({scrollTop:position}, speed, "swing");
        return false;
    });

    let $seminar_search_form = $('.front_seminars_search_form');
    if ($seminar_search_form.length > 0) {
        $('.btn_seminar_category_any').click(function() {
            $(this).prev('input[type="checkbox"]').prop('checked', function( index, prop ){
                return !prop;
            });
            $seminar_search_form.find('input[type="submit"]').click();
        })
    }
});